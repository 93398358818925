/* eslint-disable react/jsx-no-target-blank */
// eslint-disable-next-line no-restricted-imports
import {
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import React, {useEffect, useState} from 'react';
import {theme} from '../../style/theme';
import {ListType, useAddToMailingListMutation} from '../../graphql/generated';
import {useAppToast} from '../../hooks/app-toast';
import {mixpanel} from '../../utils/mixpanel';
import {emailRegExp, sanitiseEmail} from '../../shared/consts';
import {useGeolocation} from '../../hooks/geolocation';

const CTA_EVENT = 'Home CTA Clicked';

interface GeneralMailingListBannerProps {
  title: string;
  subtitle: string;
}

export const GeneralMailingListBanner: React.FC<
  GeneralMailingListBannerProps
> = ({title, subtitle}) => {
  const {countryCode: code, isLoadingGeolocation} = useGeolocation();
  const toast = useAppToast();
  const [email, setEmail] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');

  const [addToMailingList, {loading}] = useAddToMailingListMutation({
    variables: {
      email: sanitiseEmail(email),
      countryCode,
      listType: ListType.NEWSLETTER,
    },
    onCompleted: () => {
      toast.showInfoToast('Email address added');
      setEmail('');
    },
    onError: () =>
      toast.showErrorToast('Something went wrong. Please try again.'),
  });

  const onSubmit = async () => {
    const validateEmail = emailRegExp.test(email);

    if (!email) return toast.showErrorToast('Email address cannot be empty');

    if (!validateEmail)
      return toast.showErrorToast('Wrong format for the email address');

    mixpanel.track(CTA_EVENT);

    return addToMailingList();
  };

  useEffect(() => {
    if (code) setCountryCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGeolocation]);

  return (
    <Center
      flexDir="column"
      px={4}
      bg="superlight"
      pt={{base: '64px', md: '76px'}}
      pb={{base: '70px', md: '80px'}}
    >
      {/* {showTrustpilotWidget && ( */}
      {/*  <div */}
      {/*    className="trustpilot-widget" */}
      {/*    data-locale="en-GB" */}
      {/*    data-template-id="53aa8807dec7e10d38f59f32" */}
      {/*    data-businessunit-id="616c75ffe3b2c0001d7632a7" */}
      {/*    data-style-height="150px" */}
      {/*    data-style-width="100%" */}
      {/*    data-theme="light" */}
      {/*  > */}
      {/*    <a */}
      {/*      href="https://uk.trustpilot.com/review/showpiece.com" */}
      {/*      target="_blank" */}
      {/*      rel="noopener" */}
      {/*    > */}
      {/*      Trustpilot */}
      {/*    </a> */}
      {/*  </div> */}
      {/* )} */}

      {title && (
        <Text
          textStyle={{base: 'h6', md: 'h4'}}
          color="charcoal"
          textAlign="center"
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text
          textStyle={{base: 'body2', md: 'body1'}}
          mt={{base: 2, md: 4}}
          color="charcoal"
          textAlign="center"
        >
          {subtitle}
        </Text>
      )}
      <InputGroup maxW="518px" mt={{base: 8, md: 12}}>
        <Input
          placeholder="Enter your email address"
          borderRadius="14px"
          bgColor="white"
          borderColor="superlight"
          size="lg"
          _placeholder={{color: 'charcoal', opacity: 0.4}}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <InputRightElement
          width={{base: '100px', md: '178px'}}
          height="48px"
          display={{base: 'none', md: 'block'}}
        >
          <Button
            width="100%"
            colorScheme="aqua"
            onClick={onSubmit}
            isLoading={loading}
          >
            Subscribe
          </Button>
        </InputRightElement>
      </InputGroup>
      <Button
        display={{base: 'block', md: 'none'}}
        width="100%"
        maxW="518px"
        colorScheme="aqua"
        onClick={onSubmit}
        isLoading={loading}
        mt={4}
      >
        Subscribe
      </Button>
      <Text textStyle="body5" mt={{base: 2, md: 4}} color="charcoal">
        By subscribing you agree to our{' '}
        <Link href="/privacy-policy">
          <a style={{color: theme.colors.aqua[500]}}>Privacy Policy</a>
        </Link>{' '}
        and{' '}
        <Link href="/terms-and-conditions">
          <a style={{color: theme.colors.aqua[500]}}>Terms and Conditions</a>
        </Link>
      </Text>
    </Center>
  );
};

/* eslint-disable jsx-a11y/anchor-has-content,no-unused-expressions */
import {Box} from '@chakra-ui/react';
import {GetStaticProps} from 'next';
import {ResultOf} from '@graphql-typed-document-node/core';
import React, {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';
import {ResponsiveImageType} from 'react-datocms';
import {useUserQuery} from '../graphql/generated';
import {PageLayout} from '../components/global/page-layout';
import {useAppToast} from '../hooks/app-toast';
import {HomeImageHero} from '../components/index/home-image-hero';
import {HomepageComponentLoader} from '../components/index/homepage-component-loader';
import {request} from '../graphql/api';
import {HomePageDocument} from '../graphql/datocms-generated';
import {FeaturedLogos} from '../components/global/featured-logos';

const IndexPage = ({
  pageData,
}: {
  pageData: ResultOf<typeof HomePageDocument>['homePage'];
}): JSX.Element => {
  const productsOfferingRef = useRef<HTMLDivElement | null>(null);
  const userQuery = useUserQuery();
  const toast = useAppToast();
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.includes('error')) {
      toast.showErrorToast(
        'You already have an account, please sign in with email and password',
      );
      router.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);
  return (
    <>
      <PageLayout
        firstName={userQuery.data?.user?.firstName}
        additionalContentStyles={{pt: 0}}
        hideShapes
        hideOverflow
      >
        <Box>
          <HomeImageHero
            primaryLink={pageData?.primaryLink || ''}
            primaryLinkUrl={pageData?.primaryLinkUrl || ''}
            subHeading={pageData?.subHeading || ''}
            heading={pageData?.heading || ''}
            mobileImage={
              pageData?.heroBackgroundMobileImage
                ?.responsiveImage as ResponsiveImageType
            }
            desktopImage={
              pageData?.heroBackgroundImage
                ?.responsiveImage as ResponsiveImageType
            }
          />
        </Box>

        <Box maxW={{base: '400px', md: '700px'}} margin="0 auto" mt="50px">
          <FeaturedLogos
            title={pageData?.publictionHeading}
            logos={pageData?.publictionImages as any[]}
          />
        </Box>

        {pageData?.sections &&
          pageData.sections.map((section: any) => (
            <React.Fragment key={section.id}>
              <HomepageComponentLoader
                // eslint-disable-next-line no-underscore-dangle
                component={section._modelApiKey}
                section={section}
                productsOfferingRef={productsOfferingRef}
              />
            </React.Fragment>
          ))}
      </PageLayout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const [homePage] = await Promise.all([request(HomePageDocument)]);
  return {
    revalidate: 10,
    props: {
      pageData: homePage.homePage,
    },
  };
};

export default IndexPage;

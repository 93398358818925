import {Box, Button, Flex, Image, Text} from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import router from 'next/router';
import React, {MouseEventHandler, useCallback, useState} from 'react';

interface VideoProps {
  url: string;
  isEmbedded: boolean;
  caption?: string;
  background?: string;
  button?: {text: string; url: string};
  thumbnail?: string;
}

export const Video: React.FC<VideoProps> = ({
  url,
  isEmbedded,
  caption,
  background,
  button,
  thumbnail,
}) => {
  const [playing, setPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(!isEmbedded);

  const handleOnPlay = useCallback(() => {
    if (!isEmbedded) {
      setShowThumbnail(false);
    }

    if (!playing) {
      setPlaying(true);
    }
  }, [isEmbedded, playing]);

  const handlePlayPause: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setPlaying(!playing);
    },
    [playing],
  );

  return (
    <Flex
      bg={background}
      justifyContent="center"
      alignItems="center"
      width="100%"
      px="40px"
      py={6}
    >
      <Box
        maxW={{base: '100%', md: '765px', lg: '975px'}}
        width="100%"
        py={{base: '0px', md: '64px'}}
      >
        <Box
          pt={{base: '75%', md: '56.25%'}}
          pos="relative"
          width="100%"
          borderRadius={isEmbedded ? 0 : '24px'}
          overflow={isEmbedded ? 'auto' : 'hidden'}
        >
          <Box pos="absolute" top="0px" left="0px" bottom="0px" right="0">
            <ReactPlayer
              width="100%"
              height="100%"
              url={url}
              controls={isEmbedded}
              playing={playing}
              onPlay={handleOnPlay}
              config={{
                youtube: {
                  playerVars: {
                    controls: 0,
                    rel: 0,
                    iv_load_policy: 3,
                    modestbranding: 1,
                    showInfo: 0,
                    wmode: 'transparent',
                    autohide: 1,
                  },
                  embedOptions: {
                    controls: 0,
                    rel: 0,
                    iv_load_policy: 3,
                    modestbranding: 1,
                    showInfo: 0,
                    wmode: 'transparent',
                    autohide: 1,
                  },
                },
              }}
            />
            {!isEmbedded && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                background={
                  showThumbnail
                    ? `url(${thumbnail}?auto=format&w=1000) no-repeat center`
                    : 'none'
                }
                backgroundSize="cover"
                position="absolute"
                zIndex={2}
                top={0}
                left={0}
                bottom={0}
                right={0}
                cursor="pointer"
                onClick={handlePlayPause}
              >
                {!playing && (
                  <Box>
                    <Image
                      aria-label="Play video"
                      src="/assets/video-play.png"
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
        {caption && (
          <Text mt={6} textStyle="body3_1c" textAlign="center">
            {caption}
          </Text>
        )}
        {button && (
          <Flex justifyContent="center" mt="26px">
            <Button
              size="lg"
              colorScheme="aqua"
              px="64px"
              alignSelf="center"
              onClick={() => router.push(button.url)}
            >
              {button.text}
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

// eslint-disable-next-line no-restricted-imports
// https://react-slick.neostack.com/docs/api
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from 'react-slick';
import {Box, Flex, IconButton, Icon} from '@chakra-ui/react';
import {IconArrowRight, IconArrowLeft} from '@tabler/icons';
import React, {useRef} from 'react';
import {ShowpieceCard, ShowpieceCardProps} from './showpiece-card';

interface ShowpieceCardCarouselProps {
  cards: ShowpieceCardProps[];
}

export const ShowpieceCardCarousel: React.FC<ShowpieceCardCarouselProps> = ({
  cards,
}) => {
  const slider = useRef(null);
  const settings = {
    touchMove: false,
    dots: false,
    infinite: false,
    centerMode: true,
    speed: 500,
    initialSlide: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          initialSlide: 0,
        },
      },
      {
        breakpoint: 15000,
        settings: {
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Box width="100%" position="relative">
      <Flex
        pos="absolute"
        right={0}
        bottom="100%"
        paddingBottom={{base: '20px', lg: '36px'}}
        paddingRight={{base: '0', md: '20px', lg: '60px'}}
        sx={{
          '@media (min-width: 1200px)': {
            display: 'none',
          },
        }}
      >
        <IconButton
          aria-label="Previous cord"
          variant="unstyled"
          icon={
            <Icon as={IconArrowLeft} fontSize={{base: '30px', md: '35px'}} />
          }
          size="lg"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            slider?.current?.slickPrev();
          }}
        />
        <IconButton
          aria-label="Next cord"
          variant="unstyled"
          icon={
            <Icon as={IconArrowRight} fontSize={{base: '30px', md: '35px'}} />
          }
          size="lg"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-unused-expressions
            slider?.current?.slickNext();
          }}
        />
      </Flex>
      <Box w="100%">
        <Slider ref={slider} {...settings}>
          {cards &&
            cards.map((card: ShowpieceCardProps) => (
              <Flex
                px={{base: 4, sm: 4}}
                pb={{base: 6, sm: 12}}
                width="100%"
                key={card.assetId}
                minWidth="400px"
                maxWidth="400px"
                height="720px"
                margin="0 auto"
              >
                <ShowpieceCard {...card} />
              </Flex>
            ))}
        </Slider>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line no-restricted-imports
import {Box} from '@chakra-ui/react';
import React from 'react';
import {ShowpieceCategories} from './showpiece-categories';
import {GeneralMailingListBanner} from './general-mailing-list-banner';
import {ShowpieceCardBanner} from '../showpiece-card/showpiece-card-banner';
import {BasicCallout} from './basic-callout';
import {FAQs} from '../global/faq';
import {SisterCompanyLogos} from '../global/sister-company-logos';
import {VerticleProcessSteps} from './verticle-process-steps';
import {Video} from '../sections/video';
import {StoryCarousel} from '../sections/story-carousel';
import {CenteredImageWithText} from '../sections/centered-image-with-text';
import {SocialLinkModelRecord} from '../../graphql/datocms-generated';

interface HomepageComponentLoaderProps {
  component: string;
  section: any;
  productsOfferingRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const HomepageComponentLoader: React.FC<
  HomepageComponentLoaderProps
> = ({section, component, productsOfferingRef}) => {
  switch (component) {
    case 'asset_category_banner':
      return (
        <Box pt={{base: '56px', md: '128px'}}>
          <ShowpieceCategories cards={section.cards} title={section.title} />
        </Box>
      );
    case 'general_mailing_list_banner':
      return (
        <GeneralMailingListBanner
          title={section.heading}
          subtitle={section.subHeading}
        />
      );
    case 'product_carousel':
      return (
        <Box ref={productsOfferingRef}>
          <ShowpieceCardBanner
            items={section.selectedAssets}
            heading={section.heading}
            subheading={section.productCarouselSubheading}
          />
        </Box>
      );
    case 'faq_placeholder':
      return (
        <Box mt={{base: '96px', md: '160px'}}>
          <FAQs title={section.title} />
        </Box>
      );
    case 'heading_with_button':
      return (
        <BasicCallout
          url={section.buttonUrl}
          message={section.heading}
          cta={section.buttonText}
        />
      );
    case 'sister_company_logo':
      return (
        <Box mt={{base: '80px', md: '120px'}} mx={{base: 0, lg: '74px'}}>
          <SisterCompanyLogos
            title={section.sisterCompanyHeading}
            logos={section.sisterCompanyImage}
          />
        </Box>
      );
    case 'vertical_process':
      return (
        <VerticleProcessSteps
          process={section.process as any}
          image={section.image}
        />
      );
    case 'video':
      return (
        <Box mt={{base: '40px', md: '40px'}}>
          <Video
            url={
              section.videoContent[0]?.embedVideo?.url ??
              section.videoContent[0].url
            }
            isEmbedded={!!section.videoContent[0]?.embedVideo}
            button={
              section.buttonText && section.buttonLink
                ? {text: section.buttonText, url: section.buttonLink}
                : undefined
            }
            thumbnail={section.videoContent[0]?.thumbnail?.url}
          />
        </Box>
      );
    case 'story_carousel':
      return (
        <StoryCarousel
          title={section.title}
          storyContent={section.storyContent}
        />
      );
    case 'centered_image_and_text_block_section':
      return (
        <CenteredImageWithText
          title={section?.title || ''}
          preTitle={section?.preTitle || ''}
          body={section?.body || ''}
          backgroundColor={section?.backgroundColour}
          heroImage={section?.image?.responsiveImage || undefined}
          imagePosition={section.imagePosition}
          socials={
            section?.socialLinks.map((social: SocialLinkModelRecord) => {
              return {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                linkTitle: social.linkTitle!,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                linkUrl: social.linkUrl!,
                socialIcon: {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  url: social.socialIcon!.url!,
                },
              };
            }) || []
          }
          primaryCtaText={section?.primaryCtaText}
          primaryCtaUrl={section?.primaryCtaUrl}
        />
      );
    default:
      return <></>;
  }
};

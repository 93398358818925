// eslint-disable-next-line no-restricted-imports
import {Box, Button, Flex, Text, Show} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import {ResponsiveImageType} from 'react-datocms';
import React from 'react';
import {DatoCmsImage} from '../datocms/image';

interface HomeImageHeroProps {
  primaryLink: string;
  primaryLinkUrl: string;
  subHeading: string;
  heading: string;
  desktopImage: ResponsiveImageType;
  mobileImage: ResponsiveImageType;
}

export const HomeImageHero: React.FC<HomeImageHeroProps> = ({
  primaryLink,
  primaryLinkUrl,
  subHeading,
  heading,
  desktopImage,
  mobileImage,
}) => {
  const router = useRouter();

  return (
    <Box pos="relative">
      <Box
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h="100%"
        overflow="hidden"
        zIndex={0}
        sx={{
          img: {
            objectPosition: 'top center',
          },
        }}
      >
        <Show above="md">
          <DatoCmsImage
            layout="fill"
            objectFit="cover"
            responsiveImage={desktopImage}
            preloadImage
          />
        </Show>
        <Show below="md">
          <DatoCmsImage
            layout="fill"
            objectFit="cover"
            responsiveImage={mobileImage}
            preloadImage
          />
        </Show>
        <Box
          pos="absolute"
          bottom={0}
          left={0}
          w="100%"
          h="200px"
          bgGradient="linear(to-b, transparent, white)"
        />
      </Box>
      <Flex
        minH={{base: '70vh', md: '80vh'}}
        pos="relative"
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        pt={{base: '200px', md: 0}}
      >
        <Box
          textAlign="center"
          w="100%"
          maxW="530px"
          px={{base: '30px', md: '10px'}}
          textShadow="0px 0px 5px white"
        >
          <Text textStyle={{base: 'h5', md: 'h4'}}>{heading}</Text>
          <Text textStyle="h6" mt="20px">
            {subHeading}
          </Text>
          <Button
            colorScheme="aqua"
            onClick={() => router.push(primaryLinkUrl)}
            mt={6}
          >
            {primaryLink}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

import {ResponsiveImageType} from 'react-datocms';
import {Box, Text} from '@chakra-ui/react';
import {ImageAndTextCarousel} from '../product/image-and-text-story-carousel';

export interface StoryContent {
  id: string;
  imageTitle: string;
  subtitle?: string;
  imageDescription: string;
  storyCarouselImage: {
    responsiveImage: ResponsiveImageType;
  };
}
interface StoryCarouselProps {
  title: string;
  storyContent: StoryContent[];
}

export const StoryCarousel: React.FC<StoryCarouselProps> = ({
  title,
  storyContent,
}) => {
  return (
    <Box>
      <Box>
        <Text
          textAlign="center"
          py={{base: '48px', md: '64px'}}
          textStyle={{base: 'h4_1c', md: 'h2_1c'}}
        >
          {title}
        </Text>
      </Box>
      <Box>
        <ImageAndTextCarousel slides={storyContent} />
      </Box>
    </Box>
  );
};

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Flex, Text, Icon, Button, VisuallyHidden} from '@chakra-ui/react';
import {FiArrowLeft, FiArrowRight} from 'react-icons/fi';
import {DatoCmsImage} from '../datocms/image';
import {StoryContent} from '../sections/story-carousel';

interface ImageAndTextCarouselProps {
  slides: StoryContent[];
}

export const ImageAndTextCarousel: React.FC<ImageAndTextCarouselProps> = ({
  slides,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const length = useRef<number>(0);
  const hasNext = useRef<boolean>(true);
  const hasPrev = useRef<boolean>(false);

  useEffect(() => {
    // initial load
    length.current = slides.length;
    hasNext.current = slides.length - 1 > 0;
  });

  useEffect(() => {
    hasNext.current = activeIndex < length.current - 1;
    hasPrev.current = activeIndex > 0;
  }, [activeIndex]);

  const nextHandler = useCallback(() => {
    if (!hasNext.current) return;
    setActiveIndex((prevState) => prevState + 1);
  }, [hasNext]);

  const prevHandler = useCallback(() => {
    if (!hasPrev.current) return;
    setActiveIndex((prevState) => prevState - 1);
  }, [hasPrev]);
  return (
    <Flex
      width="100%"
      px={{lg: '40px'}}
      mb={{base: '48px', md: '100px'}}
      flexDir={{base: 'column', lg: 'row'}}
      justifyContent="center"
      alignItems={{base: 'center', lg: 'flex-start'}}
    >
      {slides[activeIndex] && (
        <Box
          maxWidth={{base: '327px', md: '464px', lg: '510px'}}
          mr={{lg: 20}}
          mx="24px"
        >
          <DatoCmsImage
            style={{width: '100%', height: '100%'}}
            responsiveImage={
              slides[activeIndex].storyCarouselImage.responsiveImage
            }
          />
        </Box>
      )}
      <Flex flexDir="column">
        <Flex
          flexDir="row"
          mt={{base: '40px', lg: 0}}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            css={{'&:hover': {cursor: 'pointer', backgroundColor: '#FFFFFF'}}}
            opacity={activeIndex === 0 ? '0.5' : 'inherit'}
            onClick={prevHandler}
            fontSize="22px"
            backgroundColor="#FFFFFF"
          >
            <VisuallyHidden>Previous arrow</VisuallyHidden>
            <Icon as={FiArrowLeft} />
          </Button>
          <Box px="30px">
            <Text textStyle="body1_1c">
              {activeIndex + 1} of {slides.length}
            </Text>
          </Box>
          <Button
            css={{'&:hover': {cursor: 'pointer', backgroundColor: '#FFFFFF'}}}
            opacity={activeIndex === length.current - 1 ? '0.5' : 'inherit'}
            onClick={nextHandler}
            fontSize="22px"
            backgroundColor="#FFFFFF"
          >
            <VisuallyHidden>Next arrow</VisuallyHidden>
            <Icon as={FiArrowRight} />
          </Button>
        </Flex>
        {slides[activeIndex] && (
          <Box maxWidth={{base: '327px', md: '464px', lg: '426px'}}>
            <Text
              mb={{base: '8px', md: '16px'}}
              pt={{base: '32px'}}
              textStyle="h6_1c"
            >
              {slides[activeIndex].imageTitle}
            </Text>
            <Text mb={4} textStyle="body2_1c">
              {slides[activeIndex].subtitle}
            </Text>
            <Text textStyle="body3_1c">
              {slides[activeIndex].imageDescription}
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

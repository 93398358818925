import {Text, Flex, Image, Center} from '@chakra-ui/react';

interface FeaturedLogosProps {
  title?: string;
  logos: any[];
}

export const FeaturedLogos: React.FC<FeaturedLogosProps> = ({title, logos}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent={{base: 'center', md: 'space-between'}}
      flexWrap={{base: 'wrap', md: 'nowrap'}}
    >
      {title && (
        <Text
          textStyle="h7"
          width="100%"
          textAlign={{base: 'center', md: 'left'}}
          whiteSpace="nowrap"
        >
          {title}
        </Text>
      )}
      {logos.map((logo) => (
        <Center
          key={logo.url}
          width={{base: '30%', md: '100%'}}
          my="10px"
          ml={{md: '20px'}}
        >
          <Image src={logo.url} alt={logo.alt} w="100%" />
        </Center>
      ))}
    </Flex>
  );
};

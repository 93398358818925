// eslint-disable-next-line no-restricted-imports
import React from 'react';
import {Text, Box, Icon, Flex, Image} from '@chakra-ui/react';
import {IconChevronsDown} from '@tabler/icons';

interface ProcessStepsProps {
  title: string;
  description: string;
  isFirst?: boolean;
  isLast?: boolean;
  isHidenOnMobile?: boolean;
}

export const ProcessStep: React.FC<ProcessStepsProps> = ({
  description,
  title,
  isLast,
  isFirst,
  isHidenOnMobile,
}) => {
  return (
    <Box
      pb={{base: 8, md: 10}}
      position="relative"
      pl={{base: '60px', md: '90px'}}
      display={{
        base: isHidenOnMobile ? 'none' : 'block',
        md: 'block',
      }}
    >
      <Text as="h4" mb={1} textStyle={{base: 'h6', md: 'h5'}}>
        {title}
      </Text>
      <Text textStyle="body2">{description}</Text>
      <Box pos="absolute" top="0" left="0" height="100%" zIndex="1">
        <Box
          pos="absolute"
          top={isFirst ? '25px' : 0}
          left="25px"
          // eslint-disable-next-line no-nested-ternary
          height={isLast ? '25px' : isFirst ? 'calc(100% - 25px)' : '100%'}
          width="1px"
          bg="#C5CAD6"
          zIndex="1"
        />
        <Icon
          as={IconChevronsDown}
          color="aqua.500"
          w="50px"
          h="50px"
          zIndex="2"
          position="relative"
          strokeWidth={3}
        />
      </Box>
    </Box>
  );
};

interface step {
  title: string;
  description: string;
  hideOnMobile: boolean;
}
interface VerticleProcessStepsProps {
  process?: step[];
  image?: {url: string; alt: string};
}
export const VerticleProcessSteps: React.FC<VerticleProcessStepsProps> = ({
  process,
  image,
}) => {
  return (
    <Flex
      mt={{base: '90px', md: '120px'}}
      flexDirection={{base: 'column', md: 'row-reverse'}}
      justifyContent="center"
      align={{base: 'center', md: 'self-start'}}
    >
      <Box maxWidth="425px">
        <Box px={{base: '30px', md: '0'}}>
          {process &&
            process.map((item, i) => (
              <React.Fragment key={item.title}>
                <ProcessStep
                  isFirst={i === 0}
                  isLast={i === process.length - 1}
                  title={item.title}
                  description={item.description}
                  isHidenOnMobile={item.hideOnMobile}
                />
              </React.Fragment>
            ))}
        </Box>
      </Box>
      {image && (
        <Box maxWidth="520px">
          <Image src={`${image.url}?auto=format&w=510`} alt={image.alt} />
        </Box>
      )}
    </Flex>
  );
};

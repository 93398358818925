// eslint-disable-next-line no-restricted-imports
import {Text, Center, Box, Button} from '@chakra-ui/react';
import {useRouter} from 'next/router';

interface BasicCalloutProps {
  message: string;
  cta: string;
  url: string;
}
export const BasicCallout: React.FC<BasicCalloutProps> = (props) => {
  const router = useRouter();
  return (
    <Center mt={{base: '96px', md: '160px'}} flexDir="column" mx={4} mb="40px">
      <Text textStyle={{base: 'h6', md: 'h4'}} textAlign="center">
        {props.message}
      </Text>
      <Box>
        <Button
          width="100%"
          maxW="518px"
          colorScheme="aqua"
          onClick={() => router.push(props.url)}
          mt={6}
        >
          {props.cta}
        </Button>
      </Box>
    </Center>
  );
};

// eslint-disable-next-line no-restricted-imports
import {Text, Box, Flex, Image} from '@chakra-ui/react';
import {Logo} from '../global/logo';

interface ShowpieceCategoryCardProps {
  image: {
    url: string;
    alt?: string;
  };
  title: string;
  color?: string;
  hideOnMobile?: boolean;
}

export const ShowpieceCategoryCard: React.FC<ShowpieceCategoryCardProps> = ({
  image,
  title,
  color,
  hideOnMobile,
}) => {
  return (
    <Box
      bg="white"
      boxShadow="card"
      borderRadius={{base: '17px', md: '34px'}}
      pos="relative"
      overflow="hidden"
      w="155px"
      py={4}
      mx={{base: 1, md: 4}}
      display={{base: hideOnMobile ? 'none' : 'block', md: 'block'}}
    >
      {!hideOnMobile && (
        <Box
          pos="absolute"
          bottom="0"
          right="-25%"
          width="150%"
          pl="calc(75% - 15px)"
          transform="translateY(50%)"
          zIndex={0}
        >
          <Logo accent={color} base="#F6F6F6" />
        </Box>
      )}
      <Box pos="relative">
        <Image
          src={image.url}
          alt={image.alt}
          width={{base: '24px', md: '48px'}}
          height={{base: '24px', md: '48px'}}
          margin="0 auto"
          mb={1}
        />
        <Text
          textStyle="h7"
          width="100%"
          textAlign="center"
          fontSize={{base: '12px', md: '16px'}}
        >
          {title}
        </Text>
      </Box>
    </Box>
  );
};

interface ShowpieceCategoriesProps {
  cards: [ShowpieceCategoryCardProps];
  title?: string;
}

export const ShowpieceCategories: React.FC<ShowpieceCategoriesProps> = ({
  cards,
  title,
}) => {
  return (
    <Box>
      {title && (
        <Text
          as="h2"
          textStyle={{base: 'h5', md: 'h4'}}
          color="charcoal"
          mb={{base: '40px', md: '56px'}}
          textAlign="center"
        >
          {title}
        </Text>
      )}
      <Flex justifyContent="center" pos="relative" py="24px">
        <Box
          bg="superlight"
          width="100%"
          height="50%"
          pos="absolute"
          bottom="0"
          zIndex={0}
        />
        {cards.map((item) => (
          <ShowpieceCategoryCard {...item} />
        ))}
        <ShowpieceCategoryCard
          hideOnMobile
          title="More"
          image={{
            url: '/assets/category-more.png',
            alt: 'More icon',
          }}
        />
      </Flex>
    </Box>
  );
};

// eslint-disable-next-line no-restricted-imports
import {Center, Text, Box} from '@chakra-ui/react';
import {ShowpieceCardCarousel} from './showpiece-card-carousel';
import {ShowpieceCardProps} from './showpiece-card';

interface ShowpieceCardBannerProps {
  items: ShowpieceCardProps[];
  heading: string;
  subheading: string;
}

export const ShowpieceCardBanner: React.FC<ShowpieceCardBannerProps> = ({
  heading,
  subheading,
  items,
}) => {
  return (
    <>
      <Center
        maxW="container.xl"
        w="100%"
        m="0 auto"
        px={{base: '12px', md: '24px'}}
      >
        <Box px={{base: '100px', md: '120px', lg: '160px'}}>
          {heading && (
            <Text
              as="h2"
              textStyle={{base: 'h5', md: 'h4'}}
              color="charcoal"
              mt={{base: '100px', md: '120px'}}
              mb={{
                base: subheading ? '10px' : '40px',
                md: subheading ? '8px' : '56px',
              }}
              textAlign="center"
            >
              {heading}
            </Text>
          )}
          {subheading && (
            <Text
              as="h6"
              textStyle="h6"
              color="aqua.500"
              mt={{base: '10px', md: '8px'}}
              mb={{base: '48px', md: '40px'}}
              textAlign="center"
            >
              {subheading}
            </Text>
          )}
        </Box>
      </Center>
      <Box maxW="100%">
        <ShowpieceCardCarousel cards={items} />
      </Box>
    </>
  );
};
